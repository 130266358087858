<template>
  <div><h1 class="heading">Вопрос-ответ</h1></div>

  <div class="faq-item">
    <div class="faq-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="false">
      <div class="faq-question">
        Не могу зарегистрироваться. Выдается ошибка о сбое.
      </div>
      <div class="faq-icon">
        <span class="material-icons open-icon">add_circle</span>
      </div>
    </div>
    <div class="faq-answer collapse" id="collapse-1" >
      Обратитесь в службу поддержки по электронной почте.
      <div class="divider"></div>
    </div>
  </div>

  <div class="faq-item">
    <div class="faq-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false">
      <div class="faq-question">
        Что является логином?
      </div>
      <div class="faq-icon">
        <span class="material-icons open-icon">add_circle</span>
      </div>
    </div>
    <div class="faq-answer collapse" id="collapse-2" >
      Адрес электронной почты указанной при регистрации.
      <div class="divider"></div>
    </div>
  </div>

  <div class="faq-item">
    <div class="faq-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false">
      <div class="faq-question">
        Я забыл пароль
      </div>
      <div class="faq-icon">
        <span class="material-icons open-icon">add_circle</span>
      </div>
    </div>
    <div class="faq-answer collapse" id="collapse-3" >
      Воспользуйтесь формой восстановления пароля на странице авторизации сайта blagadaru.store
      <div class="divider"></div>
    </div>
  </div>

  <div class="faq-item">
    <div class="faq-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="false">
      <div class="faq-question">
        Где взять код для регистрации продаж?
      </div>
      <div class="faq-icon">
        <span class="material-icons open-icon">add_circle</span>
      </div>
    </div>
    <div class="faq-answer collapse" id="collapse-4" >
      Вы можете отсканировать QR код на упаковке или ввести уникальный цифровой код на странице зарегистрировать продажу.
      <div class="divider"></div>
    </div>
  </div>

  <div class="faq-item">
    <div class="faq-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapse-6" aria-expanded="false">
      <div class="faq-question">
        В нашем магазине на упаковках Maxonor, Ekko, Vigro нет QR кода программы Благадарю. Почему?
      </div>
      <div class="faq-icon">
        <span class="material-icons open-icon">add_circle</span>
      </div>
    </div>
    <div class="faq-answer collapse" id="collapse-6" >
      Поставщик у которого вы покупаете продукцию пока не подключился к программе Благодарю. Вы можете поменять поставщика у которого покупаете Maxonor, Ekko и Vigro или рекомендовать действующему поставщику подключиться к программе.
      <div class="divider"></div>
    </div>
  </div>

  <div class="faq-item">
    <div class="faq-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapse-7" aria-expanded="false">
      <div class="faq-question">
        В какой момент я могу начать тратить заработанные баллы?
      </div>
      <div class="faq-icon">
        <span class="material-icons open-icon">add_circle</span>
      </div>
    </div>
    <div class="faq-answer collapse" id="collapse-7" >
      Баллы начисляются сразу после сканирования QR кода на упаковке и размещения информации о продаже в личном кабинете на сайте blagadaru.store <br/> Они становятся доступными к списанию после оплаты магазином накладной по которой была доставлена продукция.
      <div class="divider"></div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Faq"
}
</script>

<style scoped>
h1.heading {
  margin-bottom:50px;
}
.faq-item {
  background: #F8F8F8;
  border-radius: 9px;
  padding:0px 30px;
  margin-bottom:30px;
}
.faq-header {
  display:flex;
  padding: 25px 0px;
  cursor: pointer;
}
.faq-question {
  padding-right: 10px;
  font-weight:bold;
}
.faq-icon {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
 }
.faq-answer {
}
.divider {
  height:20px;
}
.open-icon {
  transition: 0.2s ease;
}
.faq-header.collapsed > .faq-icon > .open-icon {
  color: #3663F2;
  font-size:24px;
  transform: rotate(0deg);
}
.faq-header > .faq-icon > .open-icon {
  color: #71747A;
  font-size:24px;
  transform: rotate(45deg);
}

@media (max-width: 820px) {
  .faq-item {
    padding: 0px 20px;
    margin-bottom: 20px;
  }
}

</style>
